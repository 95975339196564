import "./styles.scss"

import React from "react"

import Section from "components/Section"
import clsx from "clsx"

const Content = ({ content, isProcedure }) => {
  return (
    content && (
      <Section
        className={clsx(
          "legals-content",
          isProcedure && "legals-content--procedure"
        )}
        top={false}
        bottom={false}
      >
        <div className="row justify-content-center">
          <div
            className="col-md-10"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </Section>
    )
  )
}

export default Content
