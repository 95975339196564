import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import { useLocale } from "context/locale"

const SubpageBack = ({ target }) => {
  const { t } = useLocale()

  return (
    <Link to={t(target ?? "/")} className="subpage-back">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <div className="subpage-back__wrapper">
              <span>{t("powrót")}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default SubpageBack
