import "./styles.scss"

import React from "react"
import { Link } from "gatsby"
import clsx from "clsx"

import { useLocale } from "context/locale"

const Breadcrumbs = ({ parent, title, theme }) => {
  const { t } = useLocale()

  return (
    <div className={clsx("breadcrumbs", theme && `breadcrumbs--${theme}`)}>
      <div className="container-fluid">
        <div className="breadcrumbs__wrapper">
          <Link to={t("/")}>{t("Strona główna")}</Link>
          {parent && (
            <>
              <span>&gt;</span>
              <Link to={t(parent.url)}>{t(parent.title)}</Link>
            </>
          )}
          <span>&gt;</span>
          <span>{t(title)}</span>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumbs
